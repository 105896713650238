<title>CrossTrainer</title>
<body>
    <h1 class="title">Cross To F2L</h1>
    <h2 class="ritle">A tool to practice cross and transition to F2L</h2>
    <span class="custom-dropdown">		
        <select ID="Level" onchange="this.blur();"> 
        <option VALUE="1">Level 1 – Cross requires 1 move to solve </option>
        <option VALUE="2">Level 2 – Cross requires 2 moves to solve </option>
        <option VALUE="3">Level 3 – Cross requires 3 moves to solve </option>
        <option VALUE="4">Level 4 – Cross requires 4 moves to solve </option>
        <option VALUE="5">Level 5 – Cross requires 5 moves to solve </option>
        <option VALUE="6">Level 6 – Cross requires 6 moves to solve </option>
        <option VALUE="7">Level 7 – Cross requires 7 moves to solve </option>
        <option VALUE="8">Level 8 – Cross requires 8 moves to solve </option>
        </select>
    </span>
<body>
<app-scramble></app-scramble>
