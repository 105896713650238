<h1>My Speed Cubing Stuff</h1>
<nav>
    <a class="home-link" routerLink="/cross-component" routerLinkActive="active">
        <div class="aligned">
            <img src="assets/cross-pic.png" width="200" alt="">
            <span>Cross Trainer</span>
        </div>
    </a>
    <a class="home-link" routerLink="/oll-trainer-home" routerLinkActive="active">
        <div class="aligned">
            <img src="assets/oll-pics/1.svg" width="200" alt="">
            <span>Oll Two Sided Recognition Trainer</span>
        </div>
    </a>
</nav>