import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cross',
  templateUrl: './cross.component.html',
  styleUrls: ['./cross.component.css']
})
export class CrossComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
